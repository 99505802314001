import React, { useEffect, useState } from "react";
import Card from "./Card"; // Assurez-vous du bon chemin d'importation
import Display from "./Display";
import Table from "./Table";
import { useSelectedData } from "../contexts/selectedDataContext";
import Map from "./Map";

const DataRentals = () => {
    const { selectedData } = useSelectedData();
    const [data, setData] = useState({
        active_this_week: [],
        start_this_week: [],
        end_this_week: []
    });

    useEffect(() => {
        if (!selectedData || selectedData.length === 0) return;
        if (!selectedData.start_this_week || !selectedData.end_this_week) return;
        setData(selectedData);
    }, [selectedData]);

    const structure = {
        "start_date": "Date",
        "customer_name": "Nom du client",
        "zip_code": "Code postal",
        'rental_duration': 'Durée',
        'agency_name': 'Agence',
    }

    return (
        <>
            <Display direction="col">
                <Card title="Carte" height="h-screen lg:h-full">
                    <Map rentals={data} />
                    <ul className="space-x-4 text-xl flex items-center pt-4">
                        <li className="flex items-center gap-2">
                            <p className="font-bold text-white">
                                Total : <span className="font-normal">{data.active_this_week.length + data.start_this_week.length + data.end_this_week.length}</span>
                            </p>
                        </li>
                        <li className="text-white">|</li>
                        <li className="flex items-center gap-2">
                            <div className="size-6 rounded-md bg-primary">
                            </div>
                            <p className="font-bold text-white">
                                En cours : <span className="font-normal">{data.active_this_week.length}</span>
                            </p>
                        </li>
                        <li className="text-white">|</li>
                        <li className="flex items-center gap-2">
                            <div className="size-6 rounded-md bg-blue-600">
                            </div>
                            <p className="font-bold text-white">
                                À venir : <span className="font-normal">{data.start_this_week.length}</span>
                            </p>
                        </li>
                        <li className="text-white">|</li>
                        <li className="flex items-center gap-2">
                            <div className="size-6 rounded-md bg-orange-600">
                            </div>
                            <p className="font-bold text-white">
                                Se termine : <span className="font-normal">{data.end_this_week.length}</span>
                            </p>
                        </li>
                    </ul>
                </Card>
                <Card title="Départ" height="h-max">
                    <Table data={data.start_this_week} structure={structure} />
                </Card>
                <Card title="Retour" height="h-max">
                    <Table data={data.end_this_week} structure={structure} />
                </Card>
            </Display>
        </>
    );
};

export default DataRentals;