import React, { useState, useEffect } from "react";
import Logo from '../assets/logo.png'; // Assurez-vous que le fichier SVG est importé

const Header = () => {
  const [dateTime, setDateTime] = useState(new Date());

  useEffect(() => {
    const interval = setInterval(() => {
      setDateTime(new Date());
    }, 1000); // Mettre à jour l'heure chaque seconde

    return () => clearInterval(interval); // Nettoyer l'intervalle à la destruction du composant
  }, []);

  const formattedDate = dateTime.toLocaleDateString();
  const formattedTime = dateTime.toLocaleTimeString();

  return (
    <header className="flex justify-between items-center p-4 pt-1 text-black">
      <div>
        <img src={Logo} alt="Logo" className="h-5" />
      </div>
      <div>
        <p className="text-lg">{formattedDate} {formattedTime}</p>
      </div>
    </header>
  );
};

export default Header;