import React from 'react';
import logo from '../assets/logo-icon.svg';
import { Link } from 'react-router-dom';

const NotFound = () => {
    return (
        <div className='flex items-center justify-center h-screen bg-gray-100'>
            <div className="text-center">
                <img src={logo} className="mx-auto mb-4" width={300} alt="V-Loc Logo" />
                <h1 className="text-4xl font-bold text-primary mb-2">Kiosk de V-Loc</h1>
                <h2 className="text-2xl text-secondary">Cette page n'existe pas !</h2>
                <Link to="/" className="text-primary underline">Retour à l'accueil</Link>
            </div>
        </div>
    );
}

export default NotFound;
