import React, { createContext, useContext, useState, useEffect } from 'react';

const SelectedDataContext = createContext({});

export const useSelectedData = () => useContext(SelectedDataContext);

export const SelectedDataProvider = ({ children, initialData }) => {
    const [selectedData, setSelectedData] = useState(initialData);

    useEffect(() => {
        setSelectedData(initialData);
    }, [initialData]);

    const value = {
        selectedData,
    };

    return (
        <SelectedDataContext.Provider value={value}>
            {children}
        </SelectedDataContext.Provider>
    );
};
