import React, { useEffect, useState } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import axios from 'axios';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

const Map = ({ rentals }) => {
    const [markers, setMarkers] = useState([]);

    // Fonction pour obtenir les icônes en fonction du statut
    const getIcon = (status) => {
        let color;
        switch (status) {
            case 'N':
                color = 'blue';
                break;
            case 'P':
                color = 'green';
                break;
            case 'F':
                color = 'orange';
                break;
            default:
                color = 'gray';
        }

        return L.divIcon({
            className: `custom-icon-${color}`,
            html: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="11" y="11" width="2" height="10" rx="1" fill="black"/>
                    <circle cx="12" cy="7" r="5" fill="${color}"/>
            </svg>`,
        });
    };

    useEffect(() => {
        if (!rentals) return;

        Object.keys(rentals).forEach(async (index) => {
            let status;
            switch (index) {
                case 'active_this_week':
                    status = 'P';
                    break;
                case 'start_this_week':
                    status = 'N';
                    break;
                case 'end_this_week':
                    status = 'F';
                    break;
                default:
                    status = 'gray';
            }
            rentals[index].forEach(async (rental) => {
                if (!rental.zip_code) return;
                // fetch the coordinates from the backend from the zip code
                const response = await axios.get(`/api/positions/get-coordinates/zip-code/${rental.zip_code}`);
                if (response.data.success) {
                    setMarkers((prev) => [
                        ...prev,
                        {
                            zip_code: rental.zip_code,
                            status: status,
                            long: response.data.long,
                            lat: response.data.lat,
                        },
                    ]);
                    
                } else {
                    console.error(response.data.error);
                }
            });
        });
    }, [rentals]);
        
    // Coordonées de la France pour centrer la carte
    const center = [46.603354, 1.888334];

    return (
        <MapContainer center={center} zoom={6} scrollWheelZoom={false} className='h-full w-full rounded-xl mt-3'>
            <TileLayer
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />

            {markers.map((marker, index) => (
                <Marker
                    key={index}
                    position={[parseFloat(marker.lat), parseFloat(marker.long)]} // les coordonnées doivent être obtenues avec une API
                    icon={getIcon(marker.status)}
                />
            ))}
        </MapContainer>
    );
};

export default Map;