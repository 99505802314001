import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { createBrowserRouter, Outlet, RouterProvider, useLocation, useNavigate } from 'react-router-dom';
import Screen from './components/Screen';
import axios from 'axios';
import Home from './components/Home';
import Error from './components/Error';
import NotFound from './components/NotFound';

const ProtectedRoute = () => {
    const [isAuthorized, setIsAuthorized] = useState(false);
    const navigate = useNavigate();
    const location = useLocation(); // Utilisé pour obtenir l'URL actuelle

    useEffect(() => {
        // Récupérer la clé dans l'URL
        const params = new URLSearchParams(location.search);
        const key = params.get('accesstoken');

        // Vérifier si la clé est correcte
        if (key === process.env.REACT_APP_ACCESS_TOKEN) {
            setIsAuthorized(true);
        } else {
            setIsAuthorized(false);
            // navigate('/unauthorized');  // Redirige vers une page d'erreur si la clé est incorrecte
        }
    }, [location, navigate]);

    if (!isAuthorized) {
        return <Error />;  // Affiche un message si non autorisé
    }

    return <Outlet />;  // Si autorisé, affiche les composants enfants
};

// Définition des routes
const router = createBrowserRouter([
    {
        path: "/",
        element: <Home />,
    },
    {
        path: "screen/:number",
        element: <ProtectedRoute />,  // Route protégée
        children: [
            {
                path: "",  // Chemin enfant pour Screen
                element: <Screen />,
            }
        ]
    },
    {
        path: "/unauthorized",
        element: <Error />,
    },
    // not found
    {
        path: "*",
        element: <NotFound />,
    }
]);

const token = process.env.REACT_APP_BACKEN_API_KEY;
axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
axios.defaults.baseURL = process.env.REACT_APP_BACKEND_API_URL;

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <RouterProvider router={router} />
);
