import React, { useEffect, useState } from 'react';
import { useSelectedData } from '../contexts/selectedDataContext';
import Display from './Display';
import Card from './Card';
import Loader from './Loader';
import Graphs from './Graphs';
import Chart from 'react-apexcharts';

const DataVehicles = () => {
    const { selectedData } = useSelectedData();
    const [data, setData] = useState({
        vehicles: [],
    });

    let screen = window.innerWidth;
    let fontSizeLabelsGraph = '14px';
    let fontSizeLabelsDonutGraph = '14px';
    let fontSizeDataLabelsGraph = '14px';
    // Font size 
    if (screen >= 1920) {
        fontSizeLabelsGraph = '18px';
        fontSizeLabelsDonutGraph = '36px';
        fontSizeDataLabelsGraph = '24px';
    }

    useEffect(() => {
        console.log(selectedData);
        
        if (selectedData.length === 0) return;
        if (!selectedData.vehicles) return;

        setData(selectedData);
    }, [selectedData]);

    const totalRentedVehicle = data.vehicles.reduce((total, agency) => {
        return total + agency.rented_vehicles;
    }, 0);
    const totalParkedVehicle = data.vehicles.reduce((total, agency) => {
        return total + agency.parked_vehicles;
    }, 0);
    const totalCrashedVehicle = data.vehicles.reduce((total, agency) => {
        return total + agency.crashed_vehicles;
    }, 0);

    const parcSatusGlobal = {
        categories: ['Loué', 'Parking', 'Accidenté'],
        series: [totalRentedVehicle, totalParkedVehicle, totalCrashedVehicle],
    };

    const mileageData = {
        categories: Object.keys(data.mileages || {}),
        series: [
            {
                name: 'Kilométrage',
                data: Object.values(data.mileages || {}).map((val) => val.toFixed(1)),
            }
        ]
    };

    const formatValue = (value) => {
        return new Intl.NumberFormat('fr-FR', {
            style: 'decimal',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
            useGrouping: true,
        }).format(value);
    }

    return (
        <Display direction="row">
            <Card title="Statut du parc par Agence">
                {/* Legend */}
                <div className="flex items-center justify-around py-4 overflow-x-auto grow">
                    {/* Donut chart */}
                    {data.vehicles.length == 0 ? <Loader /> : data.vehicles.map((data, index) => {
                        // Crée une nouvelle variable donutData pour chaque itération
                        let donutData = {
                            categories: ['Loué', 'Parking', 'Accidenté'],
                            series: [data.rented_vehicles, data.parked_vehicles, data.crashed_vehicles],
                        };

                        return (
                            <Graphs key={index} type="donut" data={donutData}>
                                <p className="text-center mt-4">{data.agency_name}</p>
                            </Graphs>
                        );
                    })}
                </div>

                <div className="flex justify-end pt-3 flex-none text-lg">
                    <ul className="flex items-center space-x-5">
                        <li className="flex items-center gap-1">
                            <div className="size-6 rounded-md bg-primary">
                            </div>
                            <p className="font-bold text-white">
                                Loué
                            </p>
                        </li>
                        <li className="text-white">|</li>
                        <li className="flex items-center gap-1">
                            <div className="size-6 rounded-md bg-[#60E1E0]">
                            </div>
                            <p className="font-bold text-white">
                                Parking
                            </p>
                        </li>
                        <li className="text-white">|</li>
                        <li className="flex items-center gap-1">
                            <div className="size-6 rounded-md bg-red-600">
                            </div>
                            <p className="font-bold text-white">
                                Accidenté
                            </p>
                        </li>
                    </ul>
                </div>
            </Card>
            <Card title="Statut du parc global" direction='row'>
                <div className="w-full md:w-1/2">
                    <Chart
                        options={{
                            chart: {
                                toolbar: {
                                    show: false
                                },
                                zoom: {
                                    enabled: true,
                                },
                            },
                            title: {
                                text: "",
                            },
                            xaxis: {
                                categories: parcSatusGlobal.categories || [],
                                labels: {
                                    style: {
                                        colors: 'white',
                                        fontSize: fontSizeLabelsGraph,
                                    }
                                },
                            },
                            fill: {
                                colors: ['#84DE00', '#60E1E0', '#DB0000'],
                                type: 'solid',
                            },
                            stroke: {
                                show: false,
                            },
                            legend: {
                                show: false,
                            },
                            plotOptions: {
                                pie: {
                                    donut: {
                                        labels: {
                                            show: true,
                                            name: {
                                                show: false,
                                            },
                                            value: {
                                                show: true,
                                                color: "white",
                                                formatter: (val) => {
                                                    return val;
                                                },
                                                fontSize: fontSizeLabelsDonutGraph,
                                            },
                                            total: {
                                                show: true,
                                                formatter: function (w) {
                                                    return w.globals.seriesTotals.reduce((a, b) => {
                                                        return a + b;
                                                    }, 0);
                                                }
                                            }
                                        },
                                    }
                                }
                            },
                            dataLabels: {
                                enabled: true,
                                style: {
                                    fontSize: fontSizeLabelsGraph,
                                },
                            },
                            
                        }}
                        series={parcSatusGlobal.series}
                        type="donut"
                    />
                </div>
                <div className="flex flex-col justify-end pt-3">
                    <ul className="space-y-4 text-xl">
                        <li className='bg-primary rounded-lg p-3 pr-4'>
                                <p className="font-bold text-secondary">
                                Loué : <span className="font-normal">{totalRentedVehicle}</span>
                            </p>
                            {/* Dans le tableau je veux afficher le nombre de véhivules loué dans chaque agence */}
                            <div className='flex flex-wrap space-x-3'>
                                {data.vehicles.map((agency, index) => (
                                    <p key={index} className="text-secondary">
                                        {agency.agency_name} : <span className="font-normal">{agency.rented_vehicles}</span>
                                    </p>
                                ))}
                            </div>
                        </li>
                        <li className="bg-[#60E1E0] rounded-lg p-3 pr-4 flex flex-col grow">
                            <p className="font-bold text-secondary">
                                Parking : <span className="font-normal">{totalParkedVehicle}</span>
                            </p>
                            <div className='flex flex-wrap space-x-3'>
                                {data.vehicles.map((agency, index) => (
                                    <p key={index} className="text-secondary">
                                        {agency.agency_name} : <span className="font-normal">{agency.parked_vehicles}</span>
                                    </p>
                                ))}
                            </div>
                        </li>
                        <li className="bg-red-600 rounded-lg p-3 pr-4 flex flex-col grow">
                            <p className="font-bold text-white">
                                Accidenté : <span className="font-normal">{totalCrashedVehicle}</span>
                            </p>
                            <div className='flex flex-wrap space-x-3'>
                                {data.vehicles.map((agency, index) => (
                                    <p key={index} className="text-white">
                                        {agency.agency_name} : <span className="font-normal">{agency.crashed_vehicles}</span>
                                    </p>
                                ))}
                            </div>
                        </li>
                    </ul>
                    {/* <p className="font-bold text-white mt-3">
                        Total : <span className="font-normal">{totalRentedVehicle + totalParkedVehicle + totalCrashedVehicle}</span>
                    </p> */}
                </div>
            </Card>
            <Card title="Kilométrage moyen de la flotte">
                <div className="w-full h-full">
                    <Chart
                        height={'100%'}
                        options={{
                            chart: {
                                toolbar: {
                                    show: false
                                },
                                zoom: {
                                    enabled: true,
                                },
                            },
                            title: {
                                text: "",
                            },
                            xaxis: {
                                categories: mileageData.categories || [],
                                labels: {
                                    style: {
                                        colors: 'white',
                                    }
                                },
                            },
                            yaxis: {
                                labels: {
                                    style: {
                                        colors: 'white',
                                    }
                                },

                            },
                            fill: {
                                colors: ['#84DE00', '#60E1E0', '#DB0000'],
                                type: 'solid',
                            },
                            stroke: {
                                show: false,
                            },
                            legend: {
                                show: false,
                            },
                            dataLabels: {
                                enabled: true,
                                formatter: function (val, opt) {
                                    return formatValue(val) + ' km';
                                },
                                dropShadow: {
                                    enabled: true,
                                },
                                style: {
                                    fontSize: '14px',
                                },
                            },
                            plotOptions: {
                                bar: {
                                    horizontal: false,
                                    borderRadius: 5,
                                },
                            }
                        }}
                        series={mileageData.series}
                        type='bar'
                    />
                </div>
            </Card>
        </Display>
    );
}

export default DataVehicles;
