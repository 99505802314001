import React from 'react';
import logo from '../assets/logo-icon.svg';

const Error = () => {
    return (
        <div className='flex items-center justify-center h-screen bg-gray-100'>
            <div className="text-center">
                <img src={logo} className="mx-auto mb-4" width={300} alt="V-Loc Logo" />
                <h1 className="text-4xl font-bold text-primary mb-2">Kiosk de V-Loc</h1>
                <h2 className="text-2xl text-secondary">Vous n'avez pas l'autorisation d'accéder au site</h2>
            </div>
        </div>
    );
}

export default Error;
