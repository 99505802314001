import React from 'react';
import Chart from 'react-apexcharts';

const Graphs = ({ type, data, children }) => {
    // Configuration générale des graphiques

    let screen = window.innerWidth;
    let fontSizeLabelsGraph = '14px';
    let fontSizeLabelsDonutGraph = '14px';
    let fontSizeDataLabelsGraph = '14px';
    // Font size 
    if (screen >= 1920) {
        fontSizeLabelsGraph = '18px';
        fontSizeLabelsDonutGraph = '36px';
        fontSizeDataLabelsGraph = '24px';
    }

    const baseOptions = {
        chart: {
            toolbar: {
                show: false
            },
            zoom: {
                enabled: true,
            },
        },
        title: {
            text: "",
        },
        xaxis: {
            categories: data.categories || [],
            labels: {
                style: {
                    colors: 'white',
                    fontSize: fontSizeLabelsGraph,
                }
            },
        },
        yaxis: {
            labels: {
                style: {
                    colors: 'white',
                    fontSize: fontSizeLabelsGraph,
                }
            },
        },
    };

    let chartOptions = {};
    let chartSeries = [];

    if (type === 'donut') {
        // Donut chart options
        chartOptions = {
            ...baseOptions,
            fill: {
                // colors: ['#84DE00', '#E5C852', '#DB0000'],
                colors: ['#84DE00', '#60E1E0', '#DB0000'],
                type: 'solid',
            },
            legend: {
                show: false,
            },
            plotOptions: {
                pie: {
                    donut: {
                        labels: {
                            show: true,
                            name: {
                                show: false,
                            },
                            value: {
                                show: true,
                                color: "white",
                                fontSize: fontSizeLabelsDonutGraph,
                                // formatter: (val) => {
                                //     return val + "tt";
                                // },
                            },
                            total: {
                                show: true,
                                formatter: function (w) {
                                    return w.globals.seriesTotals.reduce((a, b) => {
                                        return a + b;
                                    }, 0);
                                }
                            }
                        },
                    }
                }
            },
            dataLabels: {
                dropShadow: {
                    enabled: true,
                },
                enabled: true,
                // formatter: function (val) {
                //     return val + "tt"
                // },
                style: {
                    fontSize: fontSizeLabelsGraph,
                    // colors: ['white'],
                },
            },
        };

        chartSeries = data.series || [];

    } else if (type === 'column') {
        // Column chart options
        chartOptions = {
            ...baseOptions,
            fill: {
                colors: '#84DE00',
                type: 'solid',
            },
            dataLabels: {
                enabled: true,
                style: {
                    fontSize: fontSizeDataLabelsGraph,
                    colors: ['white'],
                }
            },
            colors: "white",
            plotOptions: {
                bar: {
                    horizontal: false,
                    borderRadius: 5,
                },
            },
            makers: {
                colors: ['white'],
            },
        };

        chartSeries = data.series || [];

    } else if (type === 'mixed') {
        // Mixed chart (Columns + Line)
        chartOptions = {
            ...baseOptions,
            fill: {
                colors: '#84DE00',
                type: 'solid',
            },
            yaxis: [
                {
                    opposite: false,
                    title: {
                        text: ''
                    },
                    labels: {
                        style: {
                            colors: 'white',
                            fontSize: fontSizeLabelsGraph,
                        }
                    },
                },
            ],
            legend: {
                show: false,
            },
            stroke: {
                curve: 'smooth',
            },
            dataLabels: {
                enabled: true,
                style: {
                    fontWeight: 'bold',
                    fontSize: fontSizeDataLabelsGraph,
                }
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    borderRadius: 5,
                },
            },
        };

        chartSeries = data.series || [];
    } else {
        return <p>Type de graphique non supporté</p>;
    }

    // les différentes taille d'écran à prendre en compte (taille de l'écran)
    // 2560px, 1920px, 1440px, 1024px, 768px, 425px, 375px, 320px
    // type de graphique : donut, column, mixed
    // Par defaut donut width: 350px, height: ""
    // Par defaut column width: "", height: 300px
    // Par defaut mixed width: "", height: 350px

    let width = "";
    let height = "";

    if (screen >= 2560) {
        if (type === 'donut') {
            width = 500;
        }
        if (type === 'column') {
            height = 450;
        }
        if (type === 'mixed') {
            height = 500;
        }
    }
    if (screen >= 2000 && screen < 2560) {
        if (type === 'donut') {
            width = 440;
        }
        if (type === 'column') {
            height = 380;
        }
        if (type === 'mixed') {
            height = 400;
        }
    }
    if (screen >= 1440 && screen < 2000) {
        if (type === 'donut') {
            width = 310;
        }
        if (type === 'column') {
            height = 260;
        }
        if (type === 'mixed') {
            height = 300;
        }
    }
    if (screen >= 1024 && screen < 1440) {
        if (type === 'donut') {
            width = 250;
        }
        if (type === 'column') {
            height = 220;
        }
        if (type === 'mixed') {
            height = 250;
        }
    }
    if (screen < 1024) {
        if (type === 'donut') {
            width = 300;
        }
        if (type === 'column') {
            height = "";
        }
        if (type === 'mixed') {
            height = "";
        }
    }

    return (
        <div className={"graph-component "}>
            {/* Afficher le graphique */}
            <Chart
                options={chartOptions}
                series={chartSeries}
                type={type === 'donut' ? 'donut' : type === 'mixed' ? 'line' : 'bar'}
                height={height}
                width={width}
                className={type === 'mixed' ? 'graph_mixed pl-2' : ""}
            />

            {/* Afficher les children */}
            <div className="graph-children text-white font-bold text-xl">
                {children}
            </div>
        </div>
    );
};

export default Graphs;