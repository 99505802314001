import React from "react";

const Card = ({ title, subtitle, width, height, children, direction = 'col' }) => {
    const widthCard = width ? width : "w-full";
    const heightCard = height ? height : "";
    return (
        <div className={`${widthCard} bg-secondary rounded-2xl overflow-hidden p-6 relative lg:h-full flex flex-col ${heightCard}`}>
            {/* Titre de la carte */}
            <h2 className="text-3xl font-bold text-white flex-none">{title}</h2>
            {subtitle && <p className="text-white text-sm">{subtitle}</p>}
            {direction == 'row' ? <div className="flex flex-col md:flex-row align-middle items-center grow">{children}</div> : children}
        </div>
    );
};

export default Card;