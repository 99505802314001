// components Loader 
import { LoaderCircle } from 'lucide-react';
import React from 'react';

const Loader = () => {
    return (
        <div className="h-full w-full flex items-center justify-center">
            <LoaderCircle className="animate-spin size-20 text-primary"/>
        </div>
    );
};

export default Loader;