import React, { useEffect, useState } from "react";
import Card from "./Card"; // Assurez-vous du bon chemin d'importation
import Display from "./Display";
import Graphs from './Graphs';
import { useSelectedData } from "../contexts/selectedDataContext";
import Loader from "./Loader";
import ReactApexChart from "react-apexcharts";

const DataStatsRentals = () => {
    const { selectedData } = useSelectedData();
    const [data, setData] = useState({
        rentals_by_week: [],
        rentals_duration: []
    });

    let screen = window.innerWidth;
    let fontSizeLabelsGraph = '14px';
    let fontSizeLabelsDonutGraph = '14px';
    let fontSizeDataLabelsGraph = '14px';
    // Font size 
    if (screen >= 1920) {
        fontSizeLabelsGraph = '18px';
        fontSizeLabelsDonutGraph = '36px';
        fontSizeDataLabelsGraph = '24px';
    }

    // Utilisation de useState pour alterner les périodes sélectionnées ( Mode Columns )
    const [selectedDuration, setSelectedDuration] = useState("last_3_months"); 
    const durations = ["last_3_months", "last_year", "this_year"]; // Liste des périodes à alterner
    let intervalRef = null;

    const [currentGraphIndex, setCurrentGraphIndex] = useState(0); // Nouvel état pour stocker l'index du graphique actuellement affiché
    let intervalRefMixed = null;

    const sortDatesObject = (obj) => {
        // Extraire les clés (les dates)
        const sortedKeys = Object.keys(obj).sort((a, b) => {
            // Convertir les dates de 'DD/MM/YYYY' à des objets Date
            const [dayA, monthA, yearA] = a.split('/').map(Number);
            const [dayB, monthB, yearB] = b.split('/').map(Number);

            // Créer les objets Date correspondants
            const dateA = new Date(yearA, monthA - 1, dayA); // monthA - 1 car les mois en JS vont de 0 à 11
            const dateB = new Date(yearB, monthB - 1, dayB);

            // Comparer les dates
            return dateA - dateB;
        });        

        // Reconstruire l'objet avec les dates triées
        const sortedObj = {};
        sortedKeys.forEach((key) => {
            sortedObj[key] = obj[key];
        });
        
        return sortedObj;
    };
    let allMixedData = sortDatesObject(data['rentals_by_week']);

    let first = Object.keys(allMixedData).slice(30, 52).reduce((obj, key) => {
        obj[key] = allMixedData[key];
        return obj;
    }, {});

    // Faire un seul tableau avec les 3 objets
    let mixedDataArray = [first];


    useEffect(() => {
        if (selectedData.length === 0) return;
        if (!selectedData.rentals_by_week || !selectedData.rentals_duration) return;
        
        setData(selectedData);
    }, [selectedData]);

    // Utilisation de useEffect pour changer de graphique Mixed toutes les 10 secondes
    useEffect(() => {
        // Fonction pour alterner l'affichage des graphiques
        const changeGraph = () => {
            setCurrentGraphIndex((prevIndex) => (prevIndex + 1) % mixedDataArray.length);
        };

        // Démarrer l'intervalle toutes les 10 secondes
        intervalRefMixed = setInterval(changeGraph, 5000); // 10 secondes

        // Nettoyer l'intervalle lorsque le composant est démonté
        return () => {
        clearInterval(intervalRefMixed);
        };
    }, [mixedDataArray]);

    // Préparer les données du graphique actuel
    const currentMixedData = mixedDataArray[currentGraphIndex];
    const mixedData = {
        categories: Object.keys(currentMixedData),
        series: [
        {
            name: 'Nouvelles',
            type: 'line',
            data: Object.values(currentMixedData).map((data) => data.new),
        },
        {
            name: 'Actives',
            type: 'line',
            data: Object.values(currentMixedData).map((data) => data.active),
        }
        ]
    };


    // Utilisation de useEffect pour changer de graphique toutes les 10 secondes
    useEffect(() => {
        // Fonction pour alterner les périodes sélectionnées
        const changeDuration = () => {
        setSelectedDuration((prevDuration) => {
            const currentIndex = durations.indexOf(prevDuration);
            const nextIndex = (currentIndex + 1) % durations.length;
            return durations[nextIndex];
        });
        };

        // Démarrer l'intervalle toutes les 10 secondes
        intervalRef = setInterval(changeDuration, 10000); // 10 secondes

        // Nettoyer l'intervalle lorsque le composant est démonté
        return () => {
        clearInterval(intervalRef);
        };
    }, []);

    const structure = {
    "last_3_months": "Derniers 3 mois",
    "last_year": "Dernière année",
    "this_year": "Cette année"
    };

    const columnData = {
        categories: Object.keys(data.rentals_duration[selectedDuration] || {}),
        series: [
            {
            name: 'Durée (en mois)',
            data: Object.values(data.rentals_duration[selectedDuration] || {}).map((val) => val.toFixed(1)),
            }
        ]
    };    

    const penalitiesData = {
        categories: Object.keys(data.penalities || {}),
        series: [
            {
                name: 'Nombre de contraventions moyen par location',
                data: Object.values(data.penalities || {}).map((val) => val.toFixed(1)),
            }
        ]
    };

    return (
        <>
            <Display direction="row" reverse={true}>
                <Card title="Nombre de location par semaine">
                    <div className="py-4 overflow-hidden grow flex flex-col justify-center">
                        <Graphs type="mixed" data={mixedData} />
                    </div>
                    <ul className="space-x-4 flex items-center pt-4">
                        <li className="flex items-center gap-2">
                            <div className="size-6 rounded-md bg-[#5bcb8c]">
                            </div>
                            <p className="font-bold text-white">
                                Location en cours pendant cette semaine
                            </p>
                        </li>
                        <li className="text-white">|</li>
                        <li className="flex items-center gap-2">
                            <div className="size-6 rounded-md bg-[#0382e2]">
                            </div>
                            <p className="font-bold text-white">
                                Nouvelle location dans la semaine
                            </p>
                        </li>
                    </ul>
                </Card>
                <Card title="Durée de location (en mois)">
                    <div className="py-4 overflow-x-auto overflow-y-auto grow flex flex-col justify-center">
                        {Object.keys(data.rentals_duration).length > 0 ? (
                            <Graphs type="column" data={columnData}>
                                <p className="text-center mt-0">
                                {structure[selectedDuration]}
                                </p>
                            </Graphs>
                        ) : (
                        <Loader />
                        )}
                    </div>
                </Card> 
                <Card title="Nombre de contraventions moyen par location">
                    <div className="w-full h-full">
                        <ReactApexChart
                            height={'100%'}
                            options={{
                                chart: {
                                    toolbar: {
                                        show: false
                                    },
                                    zoom: {
                                        enabled: true,
                                    },
                                },
                                title: {
                                    text: "",
                                },
                                xaxis: {
                                    categories: penalitiesData.categories || [],
                                    labels: {
                                        style: {
                                            colors: 'white',
                                            fontSize: fontSizeLabelsGraph,
                                        }
                                    },
                                },
                                yaxis: {
                                    labels: {
                                        style: {
                                            colors: 'white',
                                            fontSize: fontSizeLabelsGraph,
                                        }
                                    },
                                },
                                fill: {
                                    colors: ['#84DE00', '#60E1E0', '#DB0000'],
                                    type: 'solid',
                                },
                                stroke: {
                                    show: false,
                                },
                                legend: {
                                    show: false,
                                },
                                plotOptions: {
                                    bar: {
                                        horizontal: false,
                                        borderRadius: 5,
                                    },

                                },
                                dataLabels: {
                                    enabled: true,
                                    style: {
                                        fontSize: fontSizeDataLabelsGraph,
                                        colors: ['white'],
                                    }
                                },
                            }}
                            series={penalitiesData.series}
                            type='bar'
                        />
                    </div>
                </Card>
            </Display>
        </>
    );
};

export default DataStatsRentals;