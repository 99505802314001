import React, { useEffect, useState } from "react";
import Header from "./Header";
import { useParams } from "react-router";
import axios from "axios";

// load displays
import Loader from "./Loader";
import DataRentals from "./DataRentals";
import DataStatsRentals from "./DataStatsRentals";
import Photo from "./Photo";
import { SelectedDataProvider } from "../contexts/selectedDataContext";
import DataVehicles from "./DataVehicles";

const Screen = () => {
    const { number } = useParams();
    const [displays, setDisplays] = useState([]);
    const [currentDisplayIndex, setCurrentDisplayIndex] = useState(0);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);

    const componentsMap = {
        DataRentals: DataRentals,
        DataStatsRentals: DataStatsRentals,
        DataVehicles: DataVehicles,
        Photo: Photo,
    };

    useEffect(() => {
        fetchDisplays();
    }, [number]);

    useEffect(() => {
        if (displays.length === 0) return;

        const currentDisplay = displays[currentDisplayIndex];
        const displayTime = currentDisplay.display_time || 300; // 300s = 5min

        // Timer pour afficher le display pendant `display_time`
        const timer = setTimeout(() => {
            if (currentDisplayIndex === displays.length - 1) {
                // Si c'est le dernier display, on recharge la liste des displays
                fetchDisplays();
                setCurrentDisplayIndex(0);
            } else {
                // Sinon, on passe au display suivant
                showNextDisplay();
            }
        }, displayTime * 1000);

        return () => clearTimeout(timer); // Nettoyer le timer à chaque changement de display
    }, [currentDisplayIndex, displays]);

    const fetchDisplays = async () => {
        try {
            const response = await axios.get(`/api/displays/screen-displays/${number}`);
            if (response.data.success && response.data.displays.length > 0) {
                setDisplays(response.data.displays);
                fetchDisplayData(response.data.displays[0].id);
                setLoading(false);
            } else {
                setDisplays([]);
                setLoading(false);
            }
        } catch (error) {
            console.log("Erreur lors de la récupération des displays:", error);
            setDisplays([]);
            setLoading(false);
        }
    };

    const fetchDisplayData = async (id) => {
        try {
            const response = await axios.get(`/api/displays/display/${id}`);
            if (response.data.success && response.data.data.data) {
                setData(response.data.data.data);
                setLoading(false);
            } else {
                console.error("Erreur lors de la récupération des données du display, aucune donnée trouvée.");
                setData([]);
                setLoading(false);
            }
        } catch (error) {
            console.log("Erreur lors de la récupération des displays:", error);
            setData([]);
            setLoading(false);
        }
    };

    const showNextDisplay = () => {
        setCurrentDisplayIndex((prevIndex) => {
            const nextIndex = (prevIndex + 1) % displays.length;
            return nextIndex;
        });
    };

    useEffect(() => {
        if (displays.length === 0) return;
        // fetch display data
        fetchDisplayData(displays[currentDisplayIndex].id);
    }, [currentDisplayIndex]);

    return (
        <div className="h-screen bg-gray-100 ">
            <main className="p-3 lg:h-[calc(100vh-40px)]">
                {loading ? (
                    <Loader />
                ) : displays.length === 0 ? (
                    <div>Aucun display à afficher.</div>
                ) : (
                    <SelectedDataProvider initialData={data}>
                        {React.createElement(
                            componentsMap[displays[currentDisplayIndex].type] || Loader, // Si le composant n'existe pas, utiliser le Loader par défaut
                            {} // Passer les données au composant
                        )}
                    </SelectedDataProvider>
                )}
            </main>
            <Header />
        </div>
    );
};

export default Screen;