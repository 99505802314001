import React from "react";

const Display = ({ direction = "row", reverse = false, children }) => {
    const layoutClass = direction === "row" ? "lg:col-span-2" : "lg:row-span-2";

    // List des enfants en tableau
    const childrenArray = React.Children.toArray(children);

    if (childrenArray.length === 0) {
        return (
            <div className="bg-secondary rounded-2xl overflow-hidden p-6 relative h-full flex items-center justify-center">
                <h2 className="flex text-3xl font-bold text-white">Aucune donnée</h2>
            </div>
        );
    }

    const childrenArrayCount = childrenArray.length;

    return (
        <div className="grid grid-cols-1 lg:grid-cols-2 lg:grid-rows-2 h-full gap-3">
            {reverse && childrenArrayCount > 1 && childrenArray[1]}
            {reverse && childrenArrayCount > 2 && childrenArray[2]}
            <div className={layoutClass}>
                {childrenArray[0]}
            </div>
            {!reverse && childrenArrayCount > 1 && childrenArray[1]}
            {!reverse && childrenArrayCount > 2 && childrenArray[2]}
        </div>
    );
};

export default Display;