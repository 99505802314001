import React, { useState, useEffect } from "react";
import { useSelectedData } from "../contexts/selectedDataContext";
import Loader from "./Loader";

const Photos = () => {
  const { selectedData } = useSelectedData();

  let UrlPhoto = [
    "https://upload.wikimedia.org/wikipedia/commons/1/1b/2020_Citro%C3%ABn_Ami_-_Flickr_-_Rutger_van_der_Maar.jpg",
    "https://cdn.automobile-propre.com/uploads/2020/10/twizy-vs-ami-0013.jpg",
    "https://www.v-loc.fr/wp-content/uploads/2023/05/IMG_0176.jpg",
  ];

  // État pour gérer l'index actuel de l'image visible
  const [currentPhotoIndex, setCurrentPhotoIndex] = useState(0);

  // Défilement automatique des images toutes les 3 secondes
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentPhotoIndex((prevIndex) => (prevIndex + 1) % UrlPhoto.length);
    }, 6000); // Changer l'image toutes les 3 secondes

    return () => clearInterval(interval); // Nettoyage de l'intervalle
  }, []);

  return (
    <div className="relative w-full h-full overflow-hidden flex">
      {UrlPhoto.map((photo, index) => (
        <div
          key={index}
          className={`absolute h-full w-full transition-opacity duration-1000 ease-in-out  ${
            currentPhotoIndex === index ? "opacity-100 scale-100" : "opacity-0"
          }`}
        >
          <img src={photo} alt="" className="h-full w-full object-cover rounded-3xl" />
        </div>
      ))}
    </div>
  );
};

export default Photos;