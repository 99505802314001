import React from "react";
import '../index.css';
import Loader from "./Loader";

const Table = ({ data, structure }) => {

    let growClass = data.length === 0 ? "grow" : "";
    
    return (
        <div className={"overflow-x-auto overflow-y-auto flex pt-3 "+growClass}>
            <table className="table-auto w-max md:w-full text-white border-separate border border-slate-600 border-spacing-0 rounded-lg">
            <thead>
                <tr>
                    {Object.values(structure).map((value, index) => (
                        <th key={index} className="p-3 text-secondary text-xl text-left font-bold bg-primary border border-secondary">{value}</th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {data.length === 0 ? (
                    <tr>
                        <td colSpan={Object.keys(structure).length} className="p-3 text-white text-center">Aucune donnée à afficher.</td>
                    </tr>
                ) : (
                    data.map((row, index) => (
                        <tr key={index}>
                            {Object.keys(structure).map((key, index) => (
                                <td key={index} className="p-3 text-lg text-white border border-slate-600">{row[key]}</td>
                            ))}
                        </tr>
                    ))
                )}
            </tbody>
            </table>
        </div>
    );
};

export default Table;